<template>
  <div class="top">
    <div class="top-content">
      <img class="logo" src="@/assets/img/logo.png" @click="() => $router.push({path: '/'})"/>
      <div class="menuList">
        <div
          v-for="(item, index) in menuList"
          :class="item.path === $route.path ? 'menu-item clicked' : 'menu-item'"
          :key="index"
          @click="handleClick(item)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "menu-index",
  data() {
    return {
      menuList: [
        {
          index: "01",
          name: "关于我们",
          value: "aboutUs",
          path: "/aboutUs",
        },
        {
          index: "02",
          name: "合作伙伴",
          value: "coopperationPartner",
          path: "/coopperationPartner",
        },
        {
          index: "03",
          name: "零碳解决方案",
          value: "zeroCarbonSolution",
          path: "/zeroCarbonSolution",
        },
        {
          index: "04",
          name: "能源管理解决方案",
          value: "sourceManageSolution",
          path: "/sourceManageSolution",
        },
        {
          index: "05",
          name: "智能电网解决方案",
          value: "smartGridSolution",
          path: "/smartGridSolution",
        },
        {
          index: "06",
          name: "项目案例 (零碳)",
          value: "zeroCarbonProjectCase",
          path: "/zeroCarbonProjectCase",
        },
        {
          index: "07",
          name: "项目案例 (能源管理)",
          value: "sourceManageProjectCase",
          path: "/sourceManageProjectCase",
        },
        {
          index: "08",
          name: "项目案例 (智能电网)",
          value: "smartGridProjectCase",
          path: "/smartGridProjectCase",
        },
        {
          index: "09",
          name: "服务与支持",
          value: "serviceAndSupport",
          path: "/serviceAndSupport",
        },
        {
          index: "10",
          name: "联系方式",
          value: "concatWay",
          path: "/concatWay",
        },
      ],
    };
  },
  methods: {
    handleClick(e) {
      if (e.path !== this.$route.fullPath) {
        this.$router.push({ path: e.path });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100vw;
  height: 10vh;
  line-height: 10vh;
  background-color: rgb(232 242 255);
  top: 0vw;
  left: 0vw;
  .top-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .logo {
    width: 8vw;
    height: 100%;
    cursor: grab;
  }
  .menu {
  }
}
.menuList {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto;
  .menu-item {
    font-size: 1vw;
    padding: 0 1vw;
    white-space: nowrap;
    // overflow: hidden;
    color: rgb(117, 119, 119);
    cursor: grab;
  }
  .clicked {
    color: rgb(150, 193, 243)
  }
  .menu-item:first-child {
    margin-left: 2vw;
  }
}
</style>