import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import '@/style/common.scss'
import '@/style/reset.scss'

// 路由守卫
router.beforeEach((to, from, next) => {
  console.log(to)
  next()
})

createApp(App).use(router).mount('#app')
