import { createWebHistory, createRouter } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/home'),
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/components/aboutUs'),
  },
  {
    path: '/coopperationPartner',
    name: 'coopperationPartner',
    component: () => import('@/components/coopperationPartner'),
  },
  {
    path: '/zeroCarbonSolution',
    name: 'zeroCarbonSolution',
    component: () => import('@/components/zeroCarbonSolution'),
  },
  {
    path: '/sourceManageSolution',
    name: 'sourceManageSolution',
    component: () => import('@/components/sourceManageSolution'),
  },
  {
    path: '/smartGridSolution',
    name: 'smartGridSolution',
    component: () => import('@/components/smartGridSolution'),
  },
  {
    path: '/zeroCarbonProjectCase',
    name: 'zeroCarbonProjectCase',
    component: () => import('@/components/zeroCarbonProjectCase'),
  },
  {
    path: '/sourceManageProjectCase',
    name: 'sourceManageProjectCase',
    component: () => import('@/components/sourceManageProjectCase'),
  },
  {
    path: '/smartGridProjectCase',
    name: 'smartGridProjectCase',
    component: () => import('@/components/smartGridProjectCase'),
  },
  {
    path: '/serviceAndSupport',
    name: 'serviceAndSupport',
    component: () => import('@/components/serviceAndSupport'),
  },
  {
    path: '/concatWay',
    name: 'concatWay',
    component: () => import('@/components/concatWay'),
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
