<template>
  <div id="app">
    <div class="menuAndRoute">
      <Menu class="menu"></Menu>
      <router-view :key="$route.fullPath" class="route"/>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/menu/index';
export default {
  name: "App",
  components: {
    Menu
  },
};
</script>

<style lang="scss" scoped>
.menuAndRoute {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .menu {
    height: 10vh;
  }
  .route {
    flex: 1
  }
}
</style>
